import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
let { REACT_APP_BACKEND_URL } = process.env;

REACT_APP_BACKEND_URL = REACT_APP_BACKEND_URL.replace(/\/$/, '');

function Callback() {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const returnedState = urlParams.get('state');
    const originalState = sessionStorage.getItem('state');
  
    if (returnedState !== originalState) {
      console.error('State does not match.');
      return;
    }
    
    if (code) {
      console.log('Authorization code:', code);
      axios.post(`${REACT_APP_BACKEND_URL}/oidc/token`, { code })
        .then(res => {
          console.log("Res:", res.data);
          sessionStorage.setItem("success", "true"); 
          sessionStorage.setItem("user", res.data.name); 
          navigate('/oidc/home');
        })
        .catch(error => {
          if (error.response) {
            console.error("Error response from server:", error.response.data);
          } else if (error.request) {
            console.error("Request made but no response received:", error.request);
          } else {
            console.error("Error setting up request:", error.message);
          }
        });
    } else {
      console.error('No authorization code received.');
    }
  }, [navigate]);
}

export default Callback;
