import Redirect from "./Redirect.js";
import Callback from "./Callback.js";
import Login from "./login/Login.js";
import LoginOIDC from "./login/LoginOIDC.js";
import Logout from "./login/Logout.js";
import UserApp from "./user/UserApp.js";
import HomeOIDC from "./user/HomeOIDC.js";
import HomeApp from "./home/HomeApp.js";
import React from "react";
import { Route, Routes } from "react-router-dom";

function Home() {
  return (
    <div className="App">
      <div>
        <Routes>
          <Route exact path="/" element={<HomeApp/>} />
          <Route exact path="/home" element={<UserApp/>} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/logout" element={<Logout/>} />
          <Route exact path="/redirect" element={<Redirect/>} />
          <Route exact path="/oidc/home" element={<HomeOIDC/>} />
          <Route exact path="/oidc/login" element={<LoginOIDC/>} />
          <Route exact path="/oidc/callback" element={<Callback/>} />
        </Routes>
      </div>
    </div>
  );
}

export default Home;
