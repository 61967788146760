import React from 'react';
import "./Login.scss";
import icon_sv_bankid from "../../assets/img/BankID_logo.svg";
import icon_no_bankid from "../../assets/img/BankID_NO_logo.svg";
import icon_mitid from "../../assets/img/MitID_logo.svg";
import icon_user from "../../assets/img/User_48px.svg";
import Nav from "../global/Navbar.js";
let { REACT_APP_CLIENT_ID, REACT_APP_OIDC_URL, REACT_APP_REDIRECT_URL } = process.env;
const oidc_url = REACT_APP_OIDC_URL;

const LoginOIDC = () => {
  const handleID = (provider) => {
    // const redirect_uri = 'https://se-demo.dev.scrive.com/oidc/callback';
    // const redirect_uri = 'http://localhost:1339/oidc/callback';
    const redirect_uri = REACT_APP_REDIRECT_URL;
    const state = crypto.randomUUID();
    sessionStorage.setItem('state', state);
    const authorizationUrl = `${oidc_url}/auth?response_type=code&scope=openid+profile&client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${encodeURIComponent(redirect_uri)}&state=${state}&prompt=login&provider=${provider}&ui_locales=en`;
    window.location.href = authorizationUrl;
  };

  return (
    <div className="home-body">
    <Nav status='Login'/>
    <div className="home-container login">
      <div className="frame">
        <img src={icon_user} alt="icon_user" className="icon_user"/>
        <h1>Login</h1>
        <h3>Using OpenID Connect</h3>
        <p>Choose an authentication method to login</p>
        <div className="logo-contrainer oidc">
        <div className="eid-btn" onClick={() => handleID('bankid_se')} >
            <img src={icon_sv_bankid} alt="icon_sv_bankid" className="logo-se"/>
          </div>
          <div className="eid-btn" onClick={() => handleID('bankid_no')} >
            <img src={icon_no_bankid} alt="icon_no_bankid" className="logo-no"/>
          </div>
          <div className="eid-btn" onClick={() => handleID('mitid')} >
            <img src={icon_mitid} alt="icon_mitid" className="logo-mitid"/>
          </div>
          <div className="eid-btn finland" onClick={() => handleID('ftn')} />
        </div>
      </div>
      </div>
    </div>
  );
};

export default LoginOIDC;
