import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
      sessionStorage.removeItem("success");
      sessionStorage.removeItem("user");
      navigate('/');
    },[navigate]);
};
export default Logout;
