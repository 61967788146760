import Nav from "../global/Navbar.js";
import "./UserApp.scss";
import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

function HomeOIDC() {
  const success = sessionStorage.getItem("success");
  const user = sessionStorage.getItem("user");
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
      if (!success || success === "false") {
        navigate('/oidc/login');
      }
    }, [navigate, success]);

  return (
    <div className="user-body">
      <Nav status="Logout" />
      <div className="user-container-text">
        {user && success ? (
          <div>
            <h3>Welcome {user}!</h3>
            <p>You have successfully logged in.</p>
          </div>
        ) : (
          <div>No user data</div>
        )}
      </div>
    </div>
  );
}

export default HomeOIDC;
