import Nav from "../global//Navbar.js";
import Form from "./Form.js";
import Alert from "./Alert.js";
import "./UserApp.scss";
import { React, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import pic_ver from "../../assets/img/verified_2.png";

function UserApp() {
  const success = sessionStorage.getItem("success");
  const navigate = useNavigate();
  const [current, setCurrent] = useState("");
  const [ successAlert, setSuccessAlert ] = useState(false);
  const [ alert, setAlert ] = useState("");
  const message = {
    email : 'An invitation message has been sent to your email.',
    mobile: 'An invitation message has been sent to your phone.',
    api: 'A confirmation message will be sent to your email after the document is signed.',
    pad: 'Proceed to sign on tablet.'
  };
  var page = null;

  useEffect(() => {
    console.log("SUCCESS",success)
      if (!success || success === "false") {
        navigate('/login');
      }
      setCurrent(page)
    }, [navigate, page, setCurrent, success]);

  const navigateForm = (content, data) => {
    if (content==="callback") {
      setAlert(message[data.delivery])
      setCurrent(page);
      setSuccessAlert(true);
      console.log('Endpoints used in request: \n scrive.com/api/v2/documents/newfromtemplate/{template_id} \n scrive.com/api/v2/documents/{document_id}/setattachments/ \n scrive.com/api/v2/documents/{document_id}/update/ \n scrive.com/api/v2/documents/{document_id}/start');
      console.log("Return data:", data.data);
    } else {
      setSuccessAlert(false);
      setCurrent(content);
    }
  };

  const clickBack = () => {
    setCurrent(page)
  };

  page = (
    <div className="user-container-home">
      <div className="flex-container">

        <div className="column column-1">
          <div className="part-container-text">
            <p>Hello!</p>
            <p>To proceed with your application click here.</p>
          </div>
        </div>

        <div className="column column-2">
          <div className="part-container-button" onClick={() => navigateForm(form, {})} >
            Create application
          </div>
        </div>

        <div className="column column-3">
          <img src={pic_ver} alt="scrive-pic" className="pic-2"/>
        </div>

      </div>
    </div>
  )

  const form = (
    <div className="user-container">
      {successAlert && <Alert setSuccessAlert={setSuccessAlert} message={alert}/>}
      <div className="form-div">
        <div className="back-div" onClick={clickBack}><ArrowBackIosNewIcon className="back-bth" style={{transform: "scale(1.2)"}}/></div>
        <Form navigateForm={navigateForm}/>
      </div>
    </div>
  )

  return (
    <div className="user-body">
      <Nav status="Logout"/>
      <div >
        {current}
        {successAlert && <Alert setSuccessAlert={setSuccessAlert} message={alert}/>}
      </div>
    </div>
  );
}

export default UserApp;
